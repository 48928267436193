import {graphql} from 'gatsby';
import React from 'react';
import SEO from 'gatsby-plugin-wpgraphql-seo';
import Layout from '~/components/Layout';
import HeroResource from '~/components/organisms/HeroResource';
import HeroResourceEmbed from '~/components/organisms/HeroResourceEmbed';
import HeroResourceDownload from '~/components/organisms/HeroResourceDownload';
import Newsletter from '~/components/organisms/Newsletter';
import ResourcesSlider from '~/components/organisms/ResourcesSlider';
import SocialSharing from '~/components/molecules/SocialSharing';
import Text from '~/components/molecules/Text';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function ResourcesArticlePage({ data: { wpPost, allWpPost } }) {
  const { title, content, resourcePostItem, categories, featuredImage, author, day, month, year} = wpPost;

  return (
    <Layout>
      <SEO post={wpPost} />

      {resourcePostItem.resourcePostItem?.embed ? (
        <HeroResourceEmbed title={title} author={author.node} categories={categories} embed={resourcePostItem.resourcePostItem.embed} />
      ) : (resourcePostItem.resourcePostItem?.download?.image && resourcePostItem.resourcePostItem?.download?.button) ? (
        <HeroResourceDownload title={title} author={author.node} categories={categories} download={resourcePostItem.resourcePostItem.download} />
      ) : (
        <HeroResource title={title} image={featuredImage?.node} author={author.node} categories={categories} />  
      )}
      
      <section id="article-content" className="my-20 md:my-36">
        <div className="container">
          <div className="grid grid-cols-12 relative">
            <div className="col-span-1">
              <div className="hidden lg:flex flex-col items-center space-y-7 sticky top-1/4">
                <div>
                  <time className="text-h2">{day}</time>
                  <div className="text-h6 text-sm">{month} {year}</div>
                </div>
                <div className="border-b border-b-yellow w-full"></div>
                <SocialSharing
                  headingText="Share"
                  headingType="h6"
                  headingClassName="md:!mr-0"
                  className="mt-16 md:flex-col items-center text-center"
                  iconsClassName="flex-col space-y-3 md:space-x-0 mt-4"
                />
              </div>
            </div>
            <FadeInWhenVisible className="col-span-10">
              <Text content={content} />
              <div className="max-w-xl mx-auto block border-b border-b-navy border-opacity-10 mt-16"></div>
              <SocialSharing className="mt-16" />
            </FadeInWhenVisible>
          </div>
          <div className="col-span-1"></div>
        </div>
      </section>

      <Newsletter />
      <ResourcesSlider
        title={'View related articles'}
        button={[
          {
            title: 'See all articles',
            url: '/resources/',
            target: '',
          },
        ]}
        posts={allWpPost.nodes}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query ArticleQuery($id: String!) {
    allWpPost(limit: 12, filter:{id: { ne: $id }, categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}} }) {
      nodes {
        ...PostPreview
      }
    }

    wpPost(id: { eq: $id }) {
      ...BlogSEO
      title
      content
      date
      day: date(formatString:"DD")
      month: date(formatString:"MMM")
      year: date(formatString:"YY")
      resourcePostItem {
        resourcePostItem {
          embed
          download {
            button {
              ... Button
            }
            image {
              ... Image
            }
          }
        }
      }
      author {
        node {
          firstName
          lastName
        }
      }
      categories {
        nodes {
          name
        }
      }
      featuredImage {
        node {
          ... Image
        }
      }
    }
  }
`;
